// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertising-policy-js": () => import("./../src/pages/advertising-policy.js" /* webpackChunkName: "component---src-pages-advertising-policy-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-community-guidelines-js": () => import("./../src/pages/community-guidelines.js" /* webpackChunkName: "component---src-pages-community-guidelines-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-in-js": () => import("./../src/pages/log-in.js" /* webpackChunkName: "component---src-pages-log-in-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promote-js": () => import("./../src/pages/promote.js" /* webpackChunkName: "component---src-pages-promote-js" */),
  "component---src-pages-safety-tips-js": () => import("./../src/pages/safety-tips.js" /* webpackChunkName: "component---src-pages-safety-tips-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

