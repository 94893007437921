import React from 'react';
import { PageContextProvider } from '@/libraries/hooks/PageContext';
import { createInstance } from '@/i18n';
import { I18nextProvider } from 'react-i18next';
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import ReduxWrapper from '@/components/ReduxWrapper';
import StripeProvider from '@/components/StripeProvider';

export const wrapRootElement = ({ element }) => {
  return (
    <StripeProvider>
      <ReduxWrapper>
        {element}
      </ReduxWrapper>
    </StripeProvider>);
}
/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  return (
    <I18nextProvider i18n={createInstance(props.pageContext.lang)}>
      <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>
    </I18nextProvider>
  );
};