const initialState = {
  isLoggedIn: false,
  user: null,
};

const ACTION_LOGIN = 'LOGIN';
const ACTION_LOGOUT = 'LOGOUT';

export const loggedIn = user => ({ type: ACTION_LOGIN, data: user });
export const loggedOut = () => ({ type: ACTION_LOGOUT });

export default (state = initialState, action) => {
  const { type } = action;  
  switch (type) {
    case ACTION_LOGIN:
      return { ...state, 
        isLoggedIn: true,
        user: action.data,
      };
    case ACTION_LOGOUT:
      return { ...state, 
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};
