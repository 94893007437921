module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7691058","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yitnow","short_name":"Yitnow","start_url":"/","background_color":"#fb2056","theme_color":"#fb2056","display":"minimal-ui","icon":"src/images/favicon.png","legacy":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
