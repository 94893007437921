import React, { useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from '@stripe/react-stripe-js';

export const StripeContext = React.createContext({
  loading: true,
  stripe: null
});

// const StripeContext = () => React.useContext(Context);


export default ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [stripe, setStripe] = React.useState(null);
  useEffect(() => {
    const stripeInit = async () => {
      const s = await loadStripe(process.env.GATSBY_STRIPE_PUBLISHED_KEY);
      setStripe(s);
      setLoading(false);
    }

    stripeInit();
  }, []);
  return (
    <StripeContext.Provider value={{ loading, stripe }}>
      <Elements stripe={stripe}>
        {children}
      </Elements>
    </StripeContext.Provider>);
};